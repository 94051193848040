
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$pps-customer-fe-primary: mat-palette($mat-indigo);
$pps-customer-fe-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$pps-customer-fe-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$pps-customer-fe-theme: mat-light-theme((
  color: (
    primary: $pps-customer-fe-primary,
    accent: $pps-customer-fe-accent,
    warn: $pps-customer-fe-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($pps-customer-fe-theme);

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import "~ng-zorro-antd/ng-zorro-antd.min.css";
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

@layer base {
  @import '01-base/variables.scss';
  @import '01-base/theming.scss';
  @import '01-base/font.scss';
  @import '01-base/document.scss';
  @import '01-base/headings.scss';
}

@layer components {
  @import '02-components/button.scss';
}

@layer utilities {
  @import '03-utilities/animations.scss';
  @import '03-utilities/link.scss';
  @import '03-utilities/shapes.scss';
}
