@mixin apply-theme($mode) {
  @each $key, $value in map-get($themes, $mode) {
    --#{$key}: #{$value};
  }

  background-color: white;
  color: var(--text);
}

.app,
.app.light {
  @include apply-theme(light);
}

.app.dark {
  @include apply-theme(dark);
}

@media (prefers-color-scheme: dark) {
  .app {
    @include apply-theme(dark);
  }
}
