@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap");

html,
body {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.block-scroll {
  overflow: hidden;
}

.base-shadow {
  box-shadow: 0px 10px 10px -10px rgb(0 0 0 / 20%),
    0px 10px 10px 10px rgb(0 0 0 / 14%), 0px 10px 10px 10px rgb(0 0 0 / 12%);
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.inner-content {
  background: #fff;
  min-height: 360px;
}

nz-footer {
  background: #fff;
  text-align: center;
  padding: 15px;
}

.bg-app {
  background: white;
}

.bg-app-opacity {
  background: var(--app-opacity);
}

.bg-accent {
  background: var(--accent);
}

.bg-primary {
  background: var(--primary);
}

.ant-card-body {
  text-align: center;
}

.white-space-normal {
  white-space: normal;
}

.app-padding-20 {
  padding: 20px;
}

.no-margin {
  margin: 0;
}

.cl-accent {
  color: var(--accent);
}

.cl-app {
  color: var(--app);
}

.cl-primary {
  color: var(--primary);
}

.cl-text {
  color: var(--text);
}

.cl-white {
  color: white;
}

.text-light {
  color: rgb(175, 175, 175);
}

/*.fs-50{
  font-size: 3vw;
  white-space: normal;
}

@media screen and (max-width: 768px) {
  .fs-50{
    font-size: 7vw;
    white-space: normal;
  }
}

@media screen and (max-width: 360px) {
  .fs-50{
    font-size: 6vw;
    white-space: normal;
  }
}*/

/* WIDTH */
/* Width */
.w-300 {
  width: 300px;
}
.w-400 {
  width: 400px;
}
.w-500 {
  width: 500px;
}
.w-600 {
  width: 600px;
}
.w-850 {
  width: 850px;
}
/* Max Width */
.mw-300 {
  max-width: 300px;
}

/* WRAP */
.wrap-1000 {
  max-width: 1000px;
  margin: 0 auto;
}
.wrap-1200 {
  max-width: 1200px;
  margin: 0 auto;
}

/* POSITION */

.p-relative {
  position: relative;
}

/* DISPLAY */
.d-block {
  display: block;
}
/* Inline */
.d-inline-block {
  display: inline-block;
}
.d-none {
  display: none !important;
}
.va-top {
  vertical-align: top;
}
.va-middle {
  vertical-align: middle;
}
/* Flex */
.d-flex {
  display: flex;
}
.flex-1 {
  flex: 1;
}
.flex-d-col {
  flex-direction: column;
}
.flex-d-row {
  flex-direction: row;
}
.align-items-start {
  align-items: start;
}
.align-items-center {
  align-items: center;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-space-between {
  justify-content: space-between;
}
.justify-content-end {
  justify-content: flex-end;
}
.flex-wrap-wrap {
  flex-wrap: wrap;
}
/* Cursor Pointer */
.cursor-pointer {
  cursor: pointer;
}
.pointer-events-none {
  pointer-events: none;
}

/* LOGO */
// .sales-guide-logo {
//   width: px;
// }
// .logo-container {
//   width: 300px;
// }

/* SPINNER */
.white-dot .ant-spin-dot-item {
  background-color: white;
}
.ant-spin-dot-item {
  background-color: var(--app);
}
.center-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* FLOAT */
.float-left {
  float: left;
}
.float-right {
  float: right;
}
.clear-bot {
  clear: both;
}

/***** HEIGHT ******/
/*** Standard ***/
.h-179 {
  height: 179px;
}
/*** Max ***/
.max-h-179 {
  max-height: 179px;
}
/*** Min ***/
.min-h-179 {
  min-height: 179px;
}
/*****/

/* MARGIN */
/* All */
.m-15-n {
  margin: -15px;
}
/* Top */
.mt-5 {
  margin-top: 5px !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.mt-32 {
  margin-top: 32px !important;
}
.mt-40 {
  margin-top: 40px !important;
}
.mt-50 {
  margin-top: 50px !important;
}
.mt-60 {
  margin-top: 60px !important;
}
.mt-70 {
  margin-top: 70px !important;
}
.mt-80 {
  margin-top: 80px !important;
}
.mt-100 {
  margin-top: 100px !important;
}
.mt-200 {
  margin-top: 200px;
}
.mt-220 {
  margin-top: 220px !important;
}
/* Right */
.mr-10 {
  margin-right: 10px !important;
}
.mr-20 {
  margin-right: 20px !important;
}
.mr-20-n {
  margin-right: -20px !important;
}
.mr-30 {
  margin-right: 30px !important;
}
.mr-30-n {
  margin-right: -30px !important;
}
.mr-40 {
  margin-right: 40px !important;
}
.mr-50 {
  margin-right: 50px !important;
}
.mr-60 {
  margin-right: 60px !important;
}
.mr-70 {
  margin-right: 70px !important;
}
.mr-85 {
  margin-right: 85px !important;
}
/* Bottom */
.mb-0 {
  margin-bottom: 0;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.mb-10 {
  margin-bottom: 10px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mb-40 {
  margin-bottom: 40px !important;
}
.mb-50 {
  margin-bottom: 50px !important;
}
.mb-60 {
  margin-bottom: 60px !important;
}
.mb-80 {
  margin-bottom: 80px !important;
}
/* Left */
.ml-10 {
  margin-left: 10px !important;
}
.ml-20 {
  margin-left: 20px !important;
}
.ml-30 {
  margin-left: 30px !important;
}
.ml-40 {
  margin-left: 40px !important;
}
.ml-50 {
  margin-left: 50px !important;
}
.ml-60 {
  margin-left: 60px !important;
}
.ml-70 {
  margin-left: 70px !important;
}
.ml-25 {
  margin-left: 25px;
}
/* Extra */
.m-0-auto {
  margin: 0 auto;
}

/***** PADDING *****/
/*** All ***/
.p-10 {
  padding: 10px !important;
}
.p-20 {
  padding: 20px !important;
}
.p-30 {
  padding: 30px !important;
}
.p-40 {
  padding: 40px !important;
}
/*** Top ***/
.pt-10 {
  padding-top: 10px !important;
}
.pt-20 {
  padding-top: 20px !important;
}
.pt-30 {
  padding-top: 30px !important;
}
.pt-40 {
  padding-top: 40px !important;
}
.pt-50 {
  padding-top: 50px !important;
}
.pt-60 {
  padding-top: 60px !important;
}
/*** Right ***/
.pr-20 {
  padding-right: 20px !important;
}
.pr-30 {
  padding-right: 30px !important;
}
/*** Bottom ***/
.pb-10 {
  padding-bottom: 10px !important;
}
.pb-20 {
  padding-bottom: 20px !important;
}
/*** Left ***/
.pl-20 {
  padding-left: 20px !important;
}
.pl-30 {
  padding-left: 30px !important;
}
.pl-40 {
  padding-left: 40px !important;
}
.pl-50 {
  padding-left: 50px !important;
}
/*** Horizontal ***/
.px-10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.px-15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
/*** Vertical ***/
.py-7 {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}
.py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.py-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

/* FONT AND TEXT */
/* Size */
.fs-8 {
  font-size: 8px;
}
.fs-10 {
  font-size: 10px;
}
.fs-11 {
  font-size: 11px;
}
.fs-12 {
  font-size: 12px;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px;
}
.fs-26 {
  font-size: 26px;
}
.fs-30 {
  font-size: 30px;
}
.fs-40 {
  font-size: 40px;
}
/* Weight */
.fw-700 {
  font-weight: 700 !important;
}
/* Text Align */
.ta-center {
  text-align: center;
}
.ta-right {
  text-align: right;
}
/* Selected*/
.text-selected {
  color: var(--app) !important;
}

/* SHADOW */
/* Input */
.shadow-input {
  -webkit-box-shadow: 0px 3px 5px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 3px 5px 2px rgba(0, 0, 0, 0.15);
}
/* Button */
.shadow-button {
  -webkit-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.25);
}

/* BUTTON */
// .custom-button {
//   background-color: white;
//   border: 2px solid var(--app);
//   border-radius: 7px;
//   padding: 4px 6px;
//   font-weight: 600;
//   color: var(--app);
//   &:disabled,
//   [disabled] {
//     border: 2px solid lightgray;
//     color: lightgray;
//     cursor: default;
//     pointer-events: none;
//   }
// }
// .custom-button:hover {
//   background-color: var(--app);
//   color: white;
// }
// .custom-button:active {
//   background-color: rgb(110, 110, 110);
//   border: 2px solid rgb(110, 110, 110);
// }
// .custom-button:focus {
//   outline: none;
// }
// /* Selected */
// .custom-button-selected {
//   background-color: var(--app);
//   color: white;
// }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
}

/***** BORDER *****/
/*** Bottom ***/
.border-bottom {
  border-bottom: 1px solid lightgray;
}

/***** PSEUDO ELEMENTS *****/
/*** After ***/
.left-bottom-border::before {
  content: "";
  border-left: 2px solid var(--app);
  border-bottom: 2px solid var(--app);
  width: 140px;
  height: 140px;
  position: absolute;
  bottom: -1px;
  left: -1px;
  border-radius: 0 0 0 20px;
}
.right-bottom-border::before {
  content: "";
  border-right: 2px solid var(--app);
  border-bottom: 2px solid var(--app);
  width: 120px;
  height: 120px;
  position: absolute;
  bottom: -1px;
  right: -1px;
  border-radius: 0 0 20px 0;
}

/* COLORS */
/* Chart */
.chart-blue {
  color: rgba(0, 0, 255, 0.5);
}
.chart-red {
  color: rgba(255, 0, 0, 0.5);
}
.chart-green {
  color: rgba(0, 128, 0, 0.5);
}
.chart-yellow {
  color: rgb(255, 255, 0, 0.5);
}
.chart-maroon {
  color: rgb(128, 0, 0, 0.5);
}
.chart-aqua {
  color: rgb(0, 255, 255, 0.5);
}
.chart-fuchsia {
  color: rgb(255, 0, 255, 0.5);
}
.chart-lime {
  color: rgb(0, 255, 0, 0.5);
}
.chart-gray {
  color: rgb(128, 128, 128, 0.5);
}
.chart-navy {
  color: rgb(0, 0, 128, 0.5);
}
.chart-purple {
  color: rgb(128, 0, 128, 0.5);
}
.chart-olive {
  color: rgb(128, 128, 0, 0.5);
}
.chart-silver {
  color: rgb(192, 192, 192, 0.5);
}
.chart-teal {
  color: rgb(0, 128, 128, 0.5);
}

/* SCROLLBAR */
.scroll-container {
  height: calc(100vh - 236px);
}

.custom-x-scrollbar {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}
.custom-y-scrollbar {
  overflow-x: hidden;
  overflow-y: auto;
}
.custom-x-scrollbar::-webkit-scrollbar {
  height: 7px;
}
.custom-y-scrollbar::-webkit-scrollbar {
  width: 7px;
}
.custom-x-scrollbar::-webkit-scrollbar-track,
.custom-y-scrollbar::-webkit-scrollbar-track {
  background: lightgray;
}
.custom-x-scrollbar::-webkit-scrollbar-thumb,
.custom-y-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--app);
  border-radius: 5px;
}

/* #### Mobile Phones Portrait #### */
@media screen and (max-device-width: 480px) and (orientation: portrait) {
  .fs-50 {
    font-size: 5vw;
  }
  .app-layout {
    min-height: 100%;
  }
}

/* #### Mobile Phones Landscape #### */
@media screen and (max-device-width: 640px) and (orientation: landscape) {
  .fs-50 {
    font-size: 6vw;
  }
  .app-layout {
    min-height: 100%;
  }
}

/* #### Mobile Phones Portrait or Landscape #### */
@media screen and (max-device-width: 640px) {
  .fs-50 {
    font-size: 6vw;
  }
  .app-layout {
    min-height: 100%;
  }
}

/* #### iPhone 4+ Portrait or Landscape #### */
@media screen and (min-device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
  .fs-50 {
    font-size: 5vw;
  }
  .app-layout {
    min-height: 100%;
  }
}

/* #### iPhone 5 Portrait or Landscape #### */
@media (device-height: 568px) and (device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
  .fs-50 {
    font-size: 5vw;
  }
  .app-layout {
    min-height: 100%;
  }
}

/* #### iPhone 6 and 6 plus Portrait or Landscape #### */
@media (min-device-height: 667px) and (min-device-width: 375px) and (-webkit-min-device-pixel-ratio: 3) {
  .fs-50 {
    font-size: 6vw;
  }
  .app-layout {
    min-height: 100%;
  }
}

/* #### Tablets Portrait or Landscape #### */
@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .fs-50 {
    font-size: 6vw;
  }
  .app-layout {
    min-height: 100%;
  }
}

/* #### Desktops #### */
@media screen and (min-width: 1024px) {
  .fs-50 {
    font-size: 3vw;
  }
}

.fs-h4 {
  font-size: 1.25rem;
}

.mb-30 {
  margin-bottom: 30px;
}

.ant-notification-notice {
  border: 2px solid var(--app);
  border-radius: 25px;
}

// NUOVO CSS //

.smooth-scroll {
  scroll-behavior: smooth;
}

button:focus {
  outline: none;
}

.w50 {
  width: 50%;
}

.w100 {
  width: 100%;
}

.custom-button {
  height: 42px;
  padding: 0 20px;
  border-radius: 21px;
  border: none;
  font-weight: bold;
  font-size: 15px;
  background-color: var(--secondary);
  color: white;
  &:active {
    background-color: var(--dark-secondary);
  }
  &:disabled,
  [disabled] {
    background-color: lightgray;
  }
}

button.only-icon-button.secondary {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--secondary);
  svg-icon {
    fill: var(--secondary) !important;
  }
  &:disabled,
  [disabled] {
    svg-icon {
      fill: lightgray !important;
    }
    span {
      color: lightgray;
    }
    color: lightgray;
  }
  &:enabled:active,
  [enabled]:active {
    svg-icon {
      fill: var(--dark-secondary) !important;
    }
    span {
      color: var(--dark-secondary);
    }
    color: var(--dark-secondary);
  }
}

.only-text-button {
  background-color: transparent;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  color: var(--secondary);
  font-weight: bold;
  &:disabled,
  [disabled] {
    color: lightgray;
  }
  &:active {
    color: var(--dark-secondary);
  }
}

.full-wrapper-center {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: var(--utility);
}

/* BOX CONTAINER WITH BLUE BORDER */
.blue-border-box {
  &.regular-box {
    border-image-source: url("../../assets/icons/box_regular.svg");
  }
  &.slim-box {
    border-image-source: url("../../assets/icons/box_slim.svg");
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }
  border: 1px solid;
  border-image-slice: 49%;
  border-image-width: auto;
  padding: 15px;
  @media screen and (max-width: 1024px) {
    padding: 10px;
  }
  > div {
    box-shadow: 38px 37px 50px -12px rgba(0, 0, 0, 0.2);
    background-color: white;
    border-radius: 40px;
    @media screen and (max-width: 1024px) {
      border-radius: 35px;
    }
  }
}
