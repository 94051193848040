@media (prefers-reduced-motion: no-preference) {
  .spin {
    width: 80px;
    height: 80px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &::after {
      content: '';
      display: block;
      width: 45px;
      height: 45px;
      margin: 8px;
      border-radius: 50%;
      border: 6px solid #fff;
      border-color: #fff transparent #fff transparent;
      animation: spin 1.2s linear infinite;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.source {
  & ~ .target {
    top: -50rem;
    transform: translateY(20px);
    opacity: 0;
    z-index: -1;
  }

  & + .target.open {
    top: 5rem;
    transform: translateY(0);
    opacity: 1;
    z-index: 50;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .source {
    & ~ .target {
      transition: transform 300ms ease,
        opacity 300ms linear z-index 400ms linear;
      will-change: transform, opacity, z-index;
    }
  }
}
