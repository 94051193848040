h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.display-1,
.display-2,
.display-3,
.display-4 {
  @apply font-bold;
  @apply mt-0;
  color: var(--text);
}

h1,
.h1 {
  @apply text-4xl;
  @apply mb-4;
}

h2,
.h2 {
  @apply text-3xl;
  @apply mb-4;
}

h3,
.h3 {
  @apply text-2xl;
}

h4,
.h4 {
  @apply text-xl;
}

h5,
.h5 {
  @apply text-lg;
  @apply mb-4;
}

h6,
.h6 {
  @apply text-base;
  @apply mb-2;
}

.display-1 {
  @apply text-8xl;
  @apply mb-32;
}

.display-2 {
  @apply text-7xl;
  @apply mb-24;
}

.display-3 {
  @apply text-6xl;
  @apply mb-20;
}

.display-4 {
  @apply text-5xl;
  @apply mb-16;
}

/* #### Tablets Landscape #### */
@media screen and (max-width: 1024px){
  h1,
  .h1 {
    @apply text-lg;
    @apply mb-4;
  }

  h2,
  .h2 {
    @apply text-base;
    @apply mb-4;
  }

  h3,
  .h3 {
    @apply text-sm;
    @apply mb-4;
  }

  h4,
  .h4 {
    @apply text-xs;
    @apply mb-4;
  }

  h5,
  .h5 {
    @apply text-xs;
    @apply mb-4;
  }

  h6,
  .h6 {
    @apply text-xs;
    @apply mb-2;
  }
}

/* #### Tablets Portrait #### */
@media screen and (max-width: 768px){
  h1,
  .h1 {
    @apply text-2xl;
    @apply mb-4;
  }

  h2,
  .h2 {
    @apply text-xl;
    @apply mb-4;
  }

  h3,
  .h3 {
    @apply text-lg;
    @apply mb-4;
  }

  h4,
  .h4 {
    @apply text-base;
    @apply mb-4;
  }

  h5,
  .h5 {
    @apply text-base;
    @apply mb-4;
  }

  h6,
  .h6 {
    @apply text-base;
    @apply mb-2;
  }
}

/* #### Mobile Phones Landscape #### */
@media screen and (max-width: 640px){
  h1,
  .h1 {
    @apply text-xl;
    @apply mb-4;
  }

  h2,
  .h2 {
    @apply text-lg;
    @apply mb-4;
  }

  h3,
  .h3 {
    @apply text-base;
    @apply mb-4;
  }

  h4,
  .h4 {
    @apply text-sm;
    @apply mb-4;
  }

  h5,
  .h5 {
    @apply text-xs;
    @apply mb-4;
  }

  h6,
  .h6 {
    @apply text-xs;
    @apply mb-2;
  }
}

/* #### Mobile Phones Portrait #### */
@media screen and (max-width: 360px){
  .h1 {
    @apply text-base;
    @apply mb-4;
  }

  h2,
  .h2 {
    @apply text-base;
    @apply mb-4;
  }

  h3,
  .h3 {
    @apply text-sm;
    @apply mb-4;
  }

  h4,
  .h4 {
    @apply text-xs;
    @apply mb-4;
  }

  h5,
  .h5 {
    @apply text-xs;
    @apply mb-4;
  }

  h6,
  .h6 {
    @apply text-xs;
    @apply mb-2;
  }
}