.btn {
  @apply h-12;
  @apply px-6;
  @apply py-3;
  @apply font-semibold;
  @apply text-white;
  @apply rounded-lg;
  @apply inline-flex;
  @apply items-center;
  @apply justify-center;
}

.btn-primary {
  @apply bg-primary-500;
  box-shadow: 0 6px 12px 0 rgba(55, 125, 255, 0.2);
}

.btn-primary:hover {
  @apply bg-primary-400;
}

.btn:focus {
  @apply outline-none;
}

.btn-primary:focus {
  @apply ring-4;
  @apply ring-primary-500;
  @apply ring-opacity-50;
}

.btn-secondary {
  @apply bg-gray-500;
  box-shadow: 0 6px 12px 0 rgba(140, 152, 164, 0.2);
}

.btn-secondary:hover {
  @apply bg-gray-400;
}

.btn-secondary:focus {
  @apply ring-4;
  @apply ring-gray-500;
  @apply ring-opacity-50;
}

.btn-success {
  @apply bg-success-500;
  box-shadow: 0 6px 12px 0 rgba(0, 201, 167, 0.2);
}

.btn-success:hover {
  @apply bg-success-400;
}

.btn-success:focus {
  @apply ring-4;
  @apply ring-success-500;
  @apply ring-opacity-50;
}

.btn-warning {
  @apply bg-warning-500;
  box-shadow: 0 6px 12px 0 rgba(245, 202, 153, 0.2);
}

.btn-warning:hover {
  @apply bg-warning-400;
}

.btn-warning:focus {
  @apply ring-4;
  @apply ring-warning-500;
  @apply ring-opacity-50;
}

.btn-danger {
  @apply bg-danger-500;
  box-shadow: 0 6px 12px 0 rgba(239, 61, 109, 0.2);
}

.btn-danger:hover {
  @apply bg-danger-400;
}
.btn-danger:focus {
  @apply ring-4;
  @apply ring-danger-500;
  @apply ring-opacity-50;
}
